"use client";
import React, { useEffect, useState } from 'react';
import { useRouter, usePathname } from 'next/navigation';
import { Link as ScrollLink } from 'react-scroll';
import { SignedIn, SignedOut, UserButton, SignInButton } from '@clerk/nextjs';
import NextLink from 'next/link';
import Link from 'next/link';

const Navbar: React.FC = () => {
  const router = useRouter();
  const pathname = usePathname();
  const [isHomePage, setIsHomePage] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    setIsHomePage(pathname === '/');
  }, [pathname]);

  const handleRedirect = () => {
    router.push('/streams');
  };

  const handleNavigation = (sectionId: string) => {
    if (!isHomePage) {
      router.push('/');
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  };

  const NavItem: React.FC<{ to: string; children: React.ReactNode }> = ({ to, children }) => {
    if (!isMounted) return null;

    if (isHomePage) {
      return (
        <ScrollLink
          to={to}
          smooth={true}
          duration={500}
          className="text-white hover:bg-gradient-to-r hover:from-[#FD89E8] hover:to-[#FFB64F] hover:bg-clip-text hover:text-transparent transition offset--200 duration-300 cursor-pointer"
        >
          {children}
        </ScrollLink>
      );
    } else {
      return (
        <button
          onClick={() => handleNavigation(to)}
          className="text-white hover:bg-gradient-to-r hover:from-[#FD89E8] hover:to-[#FFB64F] hover:bg-clip-text hover:text-transparent transition offset--200 duration-300"
        >
          {children}
        </button>
      );
    }
  };

  if (!isMounted) {
    return null; // or a loading placeholder
  }

  return (
    <header className="flex justify-between items-center px-8 py-4 bg-[#171616] shadow-md sticky top-0 z-50 md:flex">
      <div className="flex items-center">
        <a href="/">
          <img src="https://i.ibb.co/RNY8hVL/Group-5454.png" alt="Logo" className="h-10 pt-2" />
        </a>
      </div>
      <ul className="flex space-x-6 hidden md:flex">
        {/* <li><NavItem to="features">Features</NavItem></li> */}
        <li><NavItem to="social-proof">Demos</NavItem></li>
        <li><NavItem to="how-it-works">How It Works</NavItem></li>
        <li>
          <NextLink href="/pricing" className="text-white hover:bg-gradient-to-r hover:from-[#FD89E8] hover:to-[#FFB64F] hover:bg-clip-text hover:text-transparent transition offset--200 duration-300">
            Pricing
          </NextLink>
        </li>
        <li><NavItem to="faq">FAQ</NavItem></li>
      </ul>
      <div className="flex items-center space-x-4">
        <SignedOut>
        <Link href="/sign-in">
          <button className="bg-gradient-to-r from-[#49A8FF] via-[#FD89E8] to-[#FFB64F] hover:from-[#FFB64F] hover:via-[#FD89E8] hover:to-[#49A8FF] text-white px-4 py-2 rounded transition duration-300 font-manrope">
            Sign In
          </button>
        </Link>
        </SignedOut>
        <SignedIn>
          <button 
            onClick={handleRedirect} 
            className="bg-gradient-to-r from-[#49A8FF] via-[#FD89E8] to-[#FFB64F] hover:from-[#FFB64F] hover:via-[#FD89E8] hover:to-[#49A8FF] text-white px-4 py-2 rounded transition duration-300 font-manrope">
            Dashboard
          </button>
        </SignedIn>
      </div>
    </header>
  );
};

export default Navbar;